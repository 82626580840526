import React, { createContext, useState } from 'react'
import styles from './styles.module.scss'
import CookieConsent  from "react-cookie-consent";
import Button from '../../components/Button';
import { Link } from 'gatsby';

export const CookiesContext = createContext({accept: null, onAccept: () => null, onReject: () => null});
export const CookiesContextProvider = ({children}) => {
    const [accept, setAccept] = useState(null);
    return (
        <CookiesContext.Provider value={accept}>
            {children}

            <CookieConsent
                location="none"
                enableDeclineButton
                flipButtons
                ButtonComponent={Button}
                customDeclineButtonProps={{border: false, className: styles.cookieConsentDeclineButton}}
                customButtonProps={{border: false}}
                buttonText="Accept"
                declineButtonText="Decline"
                ariaAcceptLabel="Accept"
                ariaDeclineLabel="Decline"
                contentClasses={styles.cookieConsentContent}
                containerClasses={styles.cookieConsentContainer}
            >
                LiveYou uses cookies to improve user experience on our website. By continuing to use our website, you agree to the use of cookies.
                For more information, please see our <Link to="/privacy-policy">privacy policy</Link>.
            </CookieConsent>
        </CookiesContext.Provider>
    )
}